<template>
    <v-container fluid>
        <div class="filter">
             <v-container>
                 <v-card class="pa-5">
                     <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                        v-model="filter.name"
                                        label="Name"
                                ></v-text-field>
                            </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-btn
                                   color="blue-grey darken-4"
                                   @click="filter_results()"
                                 >
                                   <v-icon>
                                     mdi-magnify
                                   </v-icon>
                                 </v-btn>
                         </v-col>
                     </v-row>
                 </v-card>
             </v-container>
        </div>
        <material-card title="Categories">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">id</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Children</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in items">
                        <td>{{ item.id }}</td>
                        <td @click="edit_item(item)">{{ item.name }}</td>
                        <td>
                            <div v-for="child in item.children">
                                <span @click="edit_item(item)">
                                    {{ child.name }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </material-card>
        <w-backend-manager :url="url" :count="count"
                           :type="type" :data="data"
                           :filter="filter"
                           :callback_function="callback_function"></w-backend-manager>

        <v-dialog v-model="edit_cat_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="edit_cat_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="edit_cat_dialog = false">Cancel</v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-divider></v-divider>
              <v-card-text>
                  <v-text-field
                        v-model="item.name"
                        label="Name"
                      ></v-text-field>

                  <v-text-field
                          v-model="item.slug"
                          label="url"
                  ></v-text-field>

                  <v-autocomplete
                          v-model="item.tags"
                          :disabled="isUpdating"
                          :items="tags"
                          filled
                          chips
                          color="blue-grey lighten-2"
                          label="Select"
                          item-text="name"
                          item-value="name"
                          multiple
                  >
                      <template v-slot:selection="data">
                          <v-chip
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item)"
                          >
                              {{ data.item.name }}
                          </v-chip>
                      </template>
                      <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item.name"></v-list-item-content>
                          </template>
                          <template v-else>
                              <v-list-item-content>
                                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              </v-list-item-content>
                          </template>
                      </template>
                  </v-autocomplete>

              </v-card-text>
            <v-card-actions>

            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                url: '',
                type: '',
                count: 0,
                data: [],
                items: [],
                filter: {},
                selected_index: undefined,
                edit_cat_dialog:false,
                item:{},
                autoUpdate: true,
                isUpdating: false,
                tags: [],

            }
        },
        mounted()
        {
            this.load();
        },
        watch: {
            isUpdating (val) {
                if (val) {
                    console.log(val);
                    setTimeout(() => (function(){
                        this.$hget(
                            this.$http.defaults.baseURL + '/category-manager/tags/?name=' +val ).then((response) => {
                                if(typeof response.data !== 'undefined')
                                {
                                    this.tags.push(response.data);
                                    this.tags = [...new Set(this.tags.map(a => a.id))];
                                }
                            });
                    }), 5000)
                }
            },
        },
        methods: {
            remove (item) {
                let index = item.tags.map(function(x) {return x.id; }).indexOf(item.id);
                if (index >= 0) this.item.tags.splice(index, 1)
            },
            edit_item(item)
            {
                this.tags = item.tags;
                this.item = item;
                this.edit_cat_dialog = true;
            },
            filter_results()
            {
                this.load();
            },
            load() {
                this.url = '/category-manager/categories/';
                this.type = 'get';
                this.count++;
            },
            update(item) {
                this.url = 'category-manager/categories/'+item.id+'/';
                this.data = item;
                this.type = 'patch';
                this.count++;
            },
            callback_function(r)
            {
                switch (this.type) {
                    case 'get':
                        this.items = r.data;
                        break;
                    case 'patch':
                        console.log("Updated");
                        break;
                    case 'post':
                        this.items.push(r.data);
                        break;
                    case 'delete':
                        this.items.splice(this.selected_index, 1);
                        console.log(r);
                        console.log('deleted');
                        break;
                }
            },
            delete(item, index) {
                this.url = 'category-manager/categories/'+item.id+'/';
                this.type = 'patch';
                this.selected_index = index;
                this.count++;
            },
        }
    }
</script>
